import Vue from "vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueTour from "vue-tour";

import "vue-multiselect/dist/vue-multiselect.min.css";

import App from "./App.vue";
import VModal from "vue-js-modal";
Vue.use(VModal, {
  componentName: "modal",
  dynamicDefault: { draggable: true, resizable: false },
});

import "../public/vue-multiselect-light.min.css";

import VueI18n from "vue-i18n";
import TURKISH_TRANSLATIONS from "./localization/tr";

Vue.use(VueI18n);
import "./assets/main.css";
import "vue-tour/dist/vue-tour.css";

import moment from "moment";
moment.locale("tr");

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

//ortak Karışımlar
import commonMixins from "./plugins/commonMixins.js";
Vue.mixin(commonMixins);

import * as VueGoogleMaps from "vue2-google-maps";
import { yesNoOptions } from "@/utils/detailData";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
Vue.component("GmapCluster", GmapCluster);

Vue.use(VueGoogleMaps, {
  load: {
    libraries: "places,drawing,visualization",
    key: "AIzaSyAnEh3psyJminTZV1u_a-_w4rE_hLYc_k0",
  },
});

Vue.use(VueSweetalert2);
Vue.use(VueTour);

Vue.mixin({
  computed: {
    getProfileImage() {
      return "https://cdn-icons-png.flaticon.com/512/149/149071.png";
    },
    isMobile() {
      return window.innerWidth < 1200;
    },
    getNowDate() {
      return moment().format("YYYY-MM-DD");
    },
    getYesNoList() {
      return yesNoOptions;
    },
  },
  methods: {
    dateFormatter(date, time, isOnly = true) {
      if (isOnly) return moment(date).format("DD-MM-YYYY");
      else return moment(date + "T" + time).format("DD-MM-YYYY HH:mm");
    },
    Money(value) {
      let val = 0;
      if (value != 0 && value != undefined) {
        if (value.toString().includes(".")) {
          val = Number(value.toString().replace(",", ".")).toFixed(2);
        } else {
          val = Number(value).toFixed(2);
        }
      }
      const returnVal = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return returnVal;
    },
    async swalBox(
      icon,
      title,
      text,
      isConfirm = false,
      confirmText = "Tamam",
      rejectText = "İptal",
      isInput = false
    ) {
      if (isConfirm) {
        if (isInput) {
          return this.$swal.fire({
            icon: icon,
            title: title,
            text: text,
            input: "text",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: confirmText,
            cancelButtonText: rejectText,
          });
        } else {
          return this.$swal.fire({
            icon: icon,
            title: title,
            text: text,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: confirmText,
            cancelButtonText: rejectText,
          });
        }
      } else {
        return this.$swal.fire({
          icon: icon,
          title: title,
          text: text,
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: confirmText,
        });
      }
    },
    errorBox(result, title = "HATA !") {
      if (result.status == 403 || result.status == 401) {
        this.$swal
          .fire({
            icon: "error",
            title: title,
            text: "Oturum süreniz doldu giriş sayfasına yönlendiriliyorsunuz !",
          })
          .then((acceptState) => {
            if (acceptState.isConfirmed) {
              this.$store.commit("signOut");
              this.$router.push("/");
            }
          });
      } else {
        this.$swal.fire({
          icon: "error",
          title: title,
          text: result.data.message,
        });
      }
    },
    positionMarker(speed = 0, speedDirection = 0) {
      let icon = null;
      if (speed == 0 || speedDirection == 6) {
        icon = require("@/assets/marker/2.png");
      } else {
        switch (speedDirection) {
          case 0:
            icon = require("@/assets/marker/0.png");
            break;
          case 1:
            icon = require("@/assets/marker/1.png");
            break;
          case 2:
              icon = require("@/assets/marker/8.png");
              break;
          case 3:
            icon = require("@/assets/marker/3.png");
            break;
          case 4:
            icon = require("@/assets/marker/4.png");
            break;
            case 5:
              icon = require("@/assets/marker/7.png");
              break;
          default:
            icon = require("@/assets/marker/5.png");
            break;
        }
      }
      return icon;
    },
    signOut() {
      this.swalBox(
        "warning",
        "UYARI !",
        "Çıkış yapmak istediğinize emin misiniz",
        true,
        "Evet,eminim",
        "Hayır Değilim"
      ).then((acc) => {
        if (acc.isConfirmed) {
          clearInterval(this.$store.state.intervalId);
          clearInterval(this.$store.state.sosIntervalId);
          clearInterval(this.$store.state.LTTIntervalId);

          this.$store.commit("clearActiveTab");
          this.$store.commit("pages/clearPage");
          this.$store.commit("activeLightMode");
          document.documentElement.classList.remove("dark");
          this.$store.commit("signOut");
          this.$router.push("/");
        }
      });
    },
    getLastThreeWords(address) {
      if (address) {
        const words = address.split(" ");
        // Eğer kelime sayısı 3'ten azsa, tüm kelimeleri al
        const lastThreeWords =
          words.length <= 3
            ? address
            : words[words.length - 2] + " / " + words[words.length - 3];
        return lastThreeWords;
      } else return;
      // Adresi boşluk karakterlerine göre ayır
    },
  },
});

Vue.config.productionTip = false;
const TRANSLATIONS = {
  tr: TURKISH_TRANSLATIONS,
};

const i18n = new VueI18n({
  locale: "tr",
  messages: TRANSLATIONS,
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
