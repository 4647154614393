<template>
  <div class="max-h-screen overflow-y-auto xl:pb-1 pb-10">
    <div
      class="shadow overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mb-4 h-72"
    >
      <vehicleModal :detail="selected" />

      <table class="min-w-full divide-y divide-gray-200">
        <thead
          class="bg-white border-black border-dotted border-b-2 border-opacity-20"
        >
          <tr>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 40px !important"
            >
              #
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 200px !important"
            >
              Sipariş
            </th>

            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 200px !important"
            >
              Araç
            </th>

            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 250px !important"
            >
              Konum
            </th>

            <th
              v-if="!hiddenInsert"
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 100px !important"
            ></th>
          </tr>
        </thead>
        <tbody
          v-if="!isMultipleDay"
          class="divide-y-2 divide-gray-500"
          style="background-color: #3a3a3a"
        >
          <newVehicle
            v-if="changeState == false && !hiddenInsert"
            @addItem="(r) => addItem(r)"
            :order="orders"
            :products="products"
            :customers="customers"
            :vehicleList="vehicleList"
            :selectedVehicles="List"
          />
          <tr v-show="!changeState" v-for="(item, index) in List" :key="index">
            <td class="table-td border-r border-opacity-20">
              <span># {{ index + 1 }} </span>
            </td>
            <td class="table-td border-r border-opacity-20">
              <p>#{{ item.orderNo + " / " + item.orderName }}</p>
            </td>
            <td
              class="table-td border-r border-opacity-20 flex justify-between items-center"
            >
              {{ item.vehicle }}
              <p>{{ item.capacity / 1000 }} TON</p>
              <img
                :title="item.romorkType"
                v-if="item.capacity / 1000 <= 52 && item.capacity / 1000 > 20"
                class="w-12 h-8"
                src="@/assets/vehicles/fuel-truck.png"
                alt=""
              />
              <img
                :title="item.romorkType"
                v-if="item.capacity / 1000 <= 20"
                class="w-10 h-8"
                src="@/assets/vehicles/tank-truck.png"
                alt=""
              />
              <span
                class="px-2 bg-red-600 rounded-xl text-white text-xs inline-block font-extralight"
                v-if="item.isCustom == 1"
              >
                Manuel Atama
              </span>
            </td>

            <td class="table-td border-r border-opacity-20">
              <p>{{ item.address + " / " + Money(item.distance) + " KM" }}</p>
            </td>
            <td v-if="!hiddenInsert" class="flex justify-center items-center">
              <button
                type="button"
                class="process-btn mr-2"
                @click="vehicleDetailItem(item)"
              >
                <i class="fas fa-search"></i>
              </button>
              <button
                type="button"
                class="process-btn"
                @click="deleteItem(item, index)"
              >
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <td
            colspan="5"
            class="text-center p-4 text-white"
            v-if="changeState == true && !load"
          >
            <i class="fas fa-recycle fa-3x"></i>
            <h3 class="text-2xl mt-2">ARAÇ PLANLA</h3>
            <p class="text-sm my-2 text-white text-opacity-75">
              bu sipariş için araç planlamak için planla butonuna basınız
            </p>
            <button
              class="bg-red-500 px-4 py-1 rounded"
              type="button"
              @click="calculateVehicle()"
            >
              Araç Öner
            </button>
          </td>
        </tbody>
        <tableLoader :load="load" length="1" colspan="5" />
      </table>
    </div>
  </div>
</template>
<script>
//Global Components
import vehicleModal from "@/components/modals/vehicle.detail.modal/index.vue";
import tableLoader from "@/components/general/table-loader.vue";

//Local Components
import newVehicle from "./new.vehicle.vue";

//Utils
import { getCustomers, getLocationAll, getProducts } from "@/utils/detailData";
import {
  recommendMultipleVehiclesForOrder,
  calculateDistancePriority,
  calculateCustomerPriority,
  isVehicleSuitableForOrderDetail,
} from "@/utils/recomendation";

//Networking
import axios from "axios";
import { bulkShipPlanning } from "@/networking/urlmanager";
export default {
  name: "vehicle-table",
  props: ["orders", "shipId", "isLaterAddOrder"],
  components: {
    tableLoader,
    newVehicle,
    vehicleModal,
  },
  data() {
    return {
      //isCalculationInProgress: false,
      // debouncedCalculateVehicle: _.debounce(this.calculateVehicle, 300),
      load: false,
      changeState: true,
      List: [],
      vehicleList: [],
      customers: [],
      products: [],
      dedicatedVehicles: [],
      new: {
        vehicle: "",
        dorse: "",
        driverName: "",
        driverTC: "",
      },
      isDeleted: false,
      selected: { id: "", plaque: "", vehicleId: "" },
      hiddenInsert: false,
      isRecommendationInProgress: false,
    };
  },

  methods: {
    addItem(item) {
      if (item) {
        let index = this.List.some((r) => parseInt(r.id) == parseInt(item.id));
        if (!index) {
          this.isDeleted = true;

          const vehicleIndex = this.vehicleList.findIndex(
            (vitem) => vitem.id == item.id
          );

          this.vehicleList[vehicleIndex].orderNos = [item.orderNo];
          this.List.push({
            id: item.id,
            orderNo: item.orderNo,
            orderName: item.orderName,
            plate: item.name,
            vehicle: item.name,
            address: item.address,
            dorse: item.dorse,
            capacity: item.capacity,
            driverName: item.driverName,
            driverTC: item.driverTC,
            latitude: Number(item.latitude),
            longitude: Number(item.longitude),
            lastProduct: item.lastProduct,
            romorkType: item.romorkType,
            isRent: item?.haveType ? item.haveType : item.isRent, // manuel ekleme için  isRent eklendi
            vehicleInspectionStartDate: item?.vehicleInspectionStartDate,
            vehicleInspectionEndDate: item?.vehicleInspectionEndDate,
            inspectionStartDate: item.inspectionStartDate,
            inspectionEndDate: item.inspectionEndDate,
            transportPurpose: item.transportPurpose,
            vehicleAdrStartDate: item?.vehicleAdrStartDate,
            vehicleAdrEndDate: item?.vehicleAdrEndDate,
            dorseAdrStartDate: item?.dorseAdrStartDate,
            dorseAdrEndDate: item?.dorseAdrEndDate,
            distance: calculateDistancePriority(
              { startLat: item.outpointLat, startLng: item.outpointLng },
              { latitude: item.latitude, longitude: item.longitude }
            ),
            isCustom: 1,
            fleetCustomers: item?.fleetCustomers,
            fleetId: item?.fleetId,
          });
          this.$emit("addVehicle", true);

          setTimeout(() => {
            this.isDeleted = false;
          }, 50);
        } else {
          this.swalBox(
            "warning",
            this.$t("general.warningTitle"),
            "Bu Araç Zaten Listede Var",
            false,
            this.$t("general.OkayTitle")
          );
        }
      }
    },
    deleteItem(detail, index) {
      if (confirm("ARACI LİSTEDEN ÇIKARMAK İSTEDİĞİNİZE EMİN MİSİNİZ?")) {
        this.isDeleted = true;
        const vehicleIndex = this.vehicleList.findIndex(
          (item) => item.id == detail.id
        );

        if (vehicleIndex > -1) {
          this.vehicleList[vehicleIndex].orderNos = [];
        }

        this.List.splice(index, 1);
        this.$emit("removeVehicle", true);

        setTimeout(() => {
          this.isDeleted = false;
        }, 50);
      }
    },
    vehicleDetailItem(item) {
      const { latitude, longitude, ...rest } = item;
      if (item.isCustom == 2) {
        this.selected = {
          outpointLng: Number(this.order.outpointLng),
          outpointLat: Number(this.order.outpointLat),
          targetPointLat: Number(this.order.targetPointLat),
          targetPointLng: Number(this.order.targetPointLng),
          ...rest,
          tonnage: item.recomendation.capacity,
          orderAmount: Number(this.order.amount),
          recomendation: {
            ...item.recomendation,
            position: { lat: latitude, lng: longitude },
          },
        };
      } else {
        const order = this.orders?.find(
          (orderItem) => orderItem.orderNo == item.orderNo
        );
        this.selected = {
          outpointLng: Number(order.outpointLng),
          outpointLat: Number(order.outpointLat),
          targetPointLat: Number(order.targetPointLat),
          targetPointLng: Number(order.targetPointLng),
          ...rest,
          tonnage: item.capacity,
          orderAmount: Number(order.amount),
          recomendation: {
            ...item.recomendation,
            name: item.vehicle,
            position: { lat: latitude, lng: longitude },
            capacity: item.capacity,
            customerPriority: calculateCustomerPriority(order, this.customers),
            distancePriority: calculateDistancePriority(
              {
                startLat: Number(order.outpointLat),
                startLng: Number(order.outpointLng),
              },
              { latitude: latitude, longitude: longitude }
            ).toFixed(2),
            ...isVehicleSuitableForOrderDetail(
              order,
              item,
              this.products,
              false,
              false
            ),
          },
        };
      }
      this.$modal.show("vehicle-modal");
    },
    vehicleRecomended(item, blockedVehicles = []) {
      if (this.isRecommendationInProgress) {
        console.log(
          "vehicleRecomended: A recommendation is already in progress"
        );
        return null;
      }

      this.isRecommendationInProgress = true;
      try {
        const order = {
          startLat: item.outpointLat,
          startLng: item.outpointLng,
          endLat: item.targetPointLat,
          endLng: item.targetPointLng,
          deliveryDate: item.deliveryDate,
          // tonnage: parseFloat(item.tonnage), neden tonnage yazıoyrmuş bilmiyorum.araştır
          tonnage: parseFloat(item.amount),
          productName: item.productType,
          token: this.$store.state.userData.token,
        };

        const recommendedVehicles = recommendMultipleVehiclesForOrder(
          order,
          this.vehicleList,
          this.customers,
          this.products,
          item.recomendedCount,
          [...this.List, ...blockedVehicles]
        );

        return recommendedVehicles;
      } finally {
        this.isRecommendationInProgress = false;
        console.log("vehicleRecomended finished");
      }
    },
    async processOrderItem(blockedVehicles = []) {
      this.List = this.List.filter((item) => item.isCustom == 1);
      if (this.totalCount < this.List.length) {
        this.List = this.List.splice(
          this.List.length - 1,
          this.List.length - this.totalCount
        );
      }

      for (let i = 0; i < this.orders?.length; i++) {
        const item = this.orders[i];
        if (!item && !item.isCustom) continue;

        let recommendedVehicles = await this.vehicleRecomended(
          item,
          blockedVehicles
        );

        if (recommendedVehicles) {
          recommendedVehicles = recommendedVehicles.map((vehicle) => {
            return {
              ...vehicle,
              orderNo: item.orderNo,
              orderName: item.name,
            };
          });

          await this.handleRecommendedVehicles(recommendedVehicles, item);
        }
      }
    },
    async handleRecommendedVehicles(recommendedVehicles, order) {
      if (
        !Array.isArray(recommendedVehicles) ||
        recommendedVehicles.length === 0
      ) {
        this.handleNoRecommendedVehicles();
        return;
      }

      if (recommendedVehicles.length < this.totalCount) {
        this.handleInsufficientVehicles(recommendedVehicles);
      }

      const newDedicatedVehicles = this.getNewDedicatedVehicles(
        recommendedVehicles,
        order
      );

      if (newDedicatedVehicles.length) {
        await this.handleDedicatedVehicles(
          newDedicatedVehicles,
          recommendedVehicles
        );
      } else {
        this.addRecommendedVehiclesToList(recommendedVehicles);
      }
    },
    handleNoRecommendedVehicles() {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Lütfen ürün kartını kontrol ediniz. Uygun bir araç bulunamadı, bu sorun ürünün taşınması için seçilen dorse türünün uygun olmamasından kaynaklanıyor olabilir. Ürünün taşınması için gerekli olan dorse türünün doğru şekilde atandığından emin olunuz.",
        false,
        this.$t("general.OkayTitle")
      );
      this.load = false;
    },
    handleInsufficientVehicles(recommendedVehicles) {
      this.insufficientErr = true;
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        `Bu iş için girilen araç sayısı ${this.recomendedCount} ama müsait araç sayısı ${recommendedVehicles.length} olduğu için araç sayısı güncellendi.`,
        false,
        this.$t("general.OkayTitle")
      );
      this.$emit("update-total-count", recommendedVehicles.length);
    },
    getNewDedicatedVehicles(recommendedVehicles, order) {
      return recommendedVehicles.filter(
        (item) =>
          item.fleetCustomers.length &&
          !item.fleetCustomers.some(
            (customer) => customer.customerId === order.customerId
          )
      );
    },
    async handleDedicatedVehicles(newDedicatedVehicles, recommendedVehicles) {
      this.dedicatedVehicles = [
        ...this.dedicatedVehicles,
        ...newDedicatedVehicles,
      ];

      const groupedData = newDedicatedVehicles.reduce((acc, item) => {
        item.fleetCustomers.forEach((customer) => {
          if (!acc[customer.customerName]) {
            acc[customer.customerName] = [];
          }
          acc[customer.customerName].push(item.plate);
        });
        return acc;
      }, {});

      const tableData = Object.entries(groupedData).map(
        ([customerName, vehicles]) => ({
          customerName,
          vehicles: vehicles.join(", "),
        })
      );

      const result = await this.$swal.fire({
        icon: "warning",
        title: "UYARI !",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Devam et",
        cancelButtonText: "Yeniden Öner",
        width: "800px",
        html: `
            <div>
              <div>
                <span class="block text-lg font-semibold mb-4">Önerilen araç listesinde başka müşteriye atanmış filoya ait araçlar var:</span>
              </div>
              <div class="overflow-x-auto">
                <table class="min-w-full bg-white border border-gray-200">
                  <thead>
                    <tr class="w-full bg-gray-200 text-left text-sm leading-normal text-gray-600 uppercase tracking-wider">
                      <th class="py-3 px-6 border-r border-gray-200">Müşteri</th>
                      <th class="py-3 px-6">Araçlar</th>
                    </tr>
                  </thead>
                  <tbody class="text-gray-700">
                    ${tableData
                      .map(
                        (entry, index) =>
                          `
                            <tr key="${index}" class="border-b border-gray-200 text-xs text-left">
                              <td class="py-3 px-6 border-r border-gray-200">${entry.customerName}</td>
                              <td class="py-3 px-6">${entry.vehicles}</td>
                            </tr>
                          `
                      )
                      .join("")}
                  </tbody>
                </table>
              </div>
              <div>
                <span class="block text-lg font-semibold mt-4">Devam etmek için DEVAM ET'e, yeniden araç önerisi yapmak için YENİDEN ÖNER'e basınız?</span>
              </div>
            </div>
          `,
      });

      if (result.isConfirmed) {
        this.addRecommendedVehiclesToList(recommendedVehicles);
      } else {
        await this.processOrderItem(this.dedicatedVehicles);
      }
    },
    addRecommendedVehiclesToList(recommendedVehicles) {
      this.List.push(...recommendedVehicles);
      this.$emit("change", this.List);
    },
    // resetItem() {
    //   this.vehicleList = [];
    // },
    async calculateVehicle() {
      if (this.vehicleList?.length == 0 || !Array.isArray(this.vehicleList)) {
        this.load = true;
        this.vehicleList = await getLocationAll(
          this.$store.state.userData.token
        );
        this.load = false;
      }

      if (this.isDeleted == true) return;

      await this.processOrderItem();
      this.changeState = false;
    },
    async save(callback) {
      try {
        const list = this.List.map((item) => {
          return {
            orderNo: item.orderNo,
            orderName: item.orderName,
            vehicle: item.vehicle,
            plate: item.vehicle,
            dorse: item.dorse,
            driverName: item.driverName,
            isCustom: item.isCustom || 0,
            driverTC: item.driverTC,
            capacity: item.capacity,
          };
        });

        if (list.length > 0) {
          await axios.post(
            bulkShipPlanning.vehicleBulkAdd,
            {
              vehicleList: JSON.stringify(list),
              shipId: this.shipId,
              isLaterAddOrder: this.isLaterAddOrder,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          );
          callback(true);
        } else {
          this.swalBox(
            "warning",
            this.$t("general.warningTitle"),
            "Devam etmek için araç eklemeniz gerekmektedir.",
            false,
            this.$t("general.OkayTitle")
          );
          callback(false);
        }
      } catch (err) {
        this.errorBox(err.response);
        callback(false);
      }
    },
  },
  computed: {
    isMultipleDay() {
      if (Array.isArray(this.recomendedList))
        return this.recomendedList.length > 0;
      else return false;
    },
  },
  async created() {
    this.changeState = this.calculateState != true;

    this.load = true;
    if (this.vehicleList.length == 0) {
      let vehicleList = await getLocationAll(this.$store.state.userData.token);

      this.vehicleList = vehicleList;
    }

    if (this.customers.length == 0)
      this.customers = await getCustomers(this.$store.state.userData.token);
    if (this.products.length == 0)
      this.products = await getProducts(this.$store.state.userData.token);
    this.load = false;

    if (!this.changeState) await this.calculateVehicle();
  },
  watch: {
    async changeState(val) {
      if (this.isDeleted) {
        this.changeState = false;
      } else if (this.totalCount > 0 && val == false)
        await this.calculateVehicle();
    },
    center() {
      if (!this.load) {
        this.changeState = true;
      }
    },
    totalCount(val) {
      if (!this.load && val > 0 && !this.isDeleted) {
        this.changeState = true;
      } else if (!this.isDeleted) {
        this.List = [];
      }
    },
    List(val) {
      return this.$emit("change", val);
    },
  },
  filters: {
    calculateTime(val) {
      this.calculateRecomended();
    },
  },
};
</script>
