<template>
  <div
    class="fixed w-full h-screen left-0 top-0 bg-white dark:bg-gray-800"
    style="z-index: 9999 !important"
  >
    <div
      class="fixed top-0 right-0 h-full bg-black bg-opacity-50 flex items-center justify-center text-white"
      style="
        z-index: 99999 !important;
        width: 80% !important;
        height: 90% !important;
        margin-top: 80px;
      "
      v-if="load"
    >
      <i class="fas fa-spinner fa-spin fa-3x"></i>
    </div>

    <hgsDetail
      name="hgs-detail-modal-full-screen"
      :List="tollsList"
      :bridges="bridgesList"
    />

    <div class="w-full flex items-center justify-center h-full">
      <div
        class="bg-white dark:bg-gray-900 shadow border-r h-full p-4 overflow-y-auto max-h-screen z-40 2xl:w-2/12 xl:w-3/12"
      >
        <div class="flex items-center justify-between mb-6">
          <label class="text-2xl my-5 font-bold block"
            >Genel Rota Bilgileri</label
          >
        </div>

        <diffInput
          title="Tehlikeli Madde Durumu"
          type="text"
          :disabled="true"
          :value="isExplosiveDefault ? 'Evet' : 'Hayır'"
        />
        <diffInput
          title="Kilometre"
          type="text"
          class="mt-6"
          :disabled="true"
          :value="totalKilometer + ' km'"
        />

        <diffInput
          title="Yolda Geçicek Zaman"
          type="text"
          class="mt-6"
          :disabled="true"
          :value="getTimeDetail(totalMinutes)"
        />
        <div @click="$modal.show('hgs-detail-modal-full-screen')">
          <diffInput
            title="Otoyol Maliyeti"
            type="button"
            class="mt-6 text-left"
            @click="$modal.show('hgs-detail-modal-full-screen')"
            :value="Money(route.totalCost) + ' ₺'"
          />
        </div>

        <diffInput
          title="Yakıt Maliyeti"
          type="text"
          class="mt-6"
          :disabled="true"
          :value="Money(fuelPrice) + ' ₺'"
        />

        <diffInput
          title="Toplam Maliyet"
          type="text"
          class="mt-6"
          :disabled="true"
          :value="Money(route.totalCost + fuelPrice) + '₺'"
        />

        <button
          type="button"
          class="z-50 bg-red-500 py-3 mt-6 rounded w-full shadow text-white"
          @click="getRoute()"
        >
          Hesapla
        </button>

        <button
          v-if="waypointsList.filter((r) => r.type == 'route').length > 0"
          class="z-50 py-2 text-sm mt-4 rounded w-full shadow text-white"
          @click="undoWaypoint()"
          :class="rectangleBounds ? 'bg-black' : 'bg-gray-800'"
          type="button"
        >
          GERİ AL
        </button>

        <button
          class="z-50 text-base rounded px-10 py-2 text-black mt-4 w-full text-center"
          @click="closeFullPanel()"
        >
          Menüye Dön
        </button>
      </div>

      <div
        class="bg-white dark:bg-gray-900 shadow border-r py-2 overflow-y-auto absolute top-0 z-30 left-0 w-screen flex items-end justify-start pl-80 pr-10"
      >
        <div class="w-3/12 mr-4">
          <label class="text-xs">Rota Türü</label>
          <select
            v-model="routeType"
            class="text-sm border px-2 py-2 rounded w-full"
          >
            <option value="1">EN HIZLI</option>
            <option value="2">EN KISA</option>
          </select>
        </div>
        <div class="w-3/12 mr-4">
          <label class="text-xs">Araç Türü</label>
          <select
            v-model="vehicleTypeId"
            class="text-sm border px-2 py-2 rounded w-full"
          >
            <option
              v-for="item in vehicleTypes"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="w-3/12 mr-4">
          <label class="text-xs">Ücretli Yollar</label>
          <select
            v-model="isToll"
            class="text-sm border px-2 py-2 rounded w-full"
          >
            <option :value="1">EVET</option>
            <option :value="0">HAYIR</option>
          </select>
        </div>
        <div class="w-2/12 mr-4">
          <label class="text-xs">Tehlikeli Madde</label>
          <select
            v-model="isExplosive"
            class="text-sm border px-2 py-2 rounded w-full"
          >
            <option :value="1">EVET</option>
            <option :value="0">HAYIR</option>
          </select>
        </div>
        <div class="w-2/12">
          <label class="text-xs">Gemi/Feribot Kullanımı</label>
          <select
            v-model="isShip"
            class="text-sm border px-2 py-2 rounded w-full"
          >
            <option :value="1">EVET</option>
            <option :value="0">HAYIR</option>
          </select>
        </div>
      </div>

      <div
        class="fixed top-0 right-0 bg-white h-screen z-20 pt-28"
        style="width: 40vh"
      >
        <h4 class="block text-center text-base font-bold mb-5">
          AKTİF ÜCRETLİ YOLLAR
        </h4>
        <div
          class="w-full overflow-y-auto border-b pb-2 border-black border-opacity-30"
          style="height: 30vh"
        >
          <div
            v-for="(item, index) in pricingRoadList"
            @click="addAvoidPoint(item)"
            class="w-full flex justify-between items-center px-4 border-b p-2 cursor-pointer select-none"
            :key="index"
          >
            <p class="w-7/12 text-sm cursor-pointer select-none break-words">
              {{ item.name }}
            </p>
            <label class="text-sm text-right w-3/12 cursor-pointer select-none">
              {{ Money(item.price) }} ₺
            </label>

            <i class="fas fa-times text-red-500 text-sm w-1/12"></i>
          </div>
        </div>

        <div class="text-sm mt-6" v-if="avoidList.length > 0">
          <h4 class="text-center font-bold mb-5">YASAKLANAN ÜCRETLİ YOLLAR</h4>
          <div
            v-for="(item, index) in avoidList"
            @click="removeAvoidList(item)"
            class="w-full flex justify-between items-center px-4 border-b p-2 cursor-pointer select-none"
            :key="index"
          >
            <p class="w-7/12 text-sm cursor-pointer select-none break-words">
              {{ item.name }}
            </p>
            <label class="text-sm text-right w-3/12 cursor-pointer select-none">
              {{ Money(item.price) }} ₺
            </label>

            <i class="fas fa-times text-red-500 text-sm w-1/12"></i>
          </div>
        </div>
      </div>

      <button
        class="absolute top-20 left-3/4 -ml-14 lg:-ml-24 z-50 p-2 text-sm mt-4 rounded shadow text-white"
        @click="toggleRectangle()"
        :class="rectangleBounds ? 'bg-black' : 'bg-gray-800'"
        type="button"
      >
        {{ rectangleBounds ? "Alanı Kaldır" : "Yasaklı Alan Ekle" }}
      </button>
      <GmapMap
        v-if="
          startPosition.lat &&
          startPosition.lng &&
          endPosition.lat &&
          endPosition.lng
        "
        :center="center"
        :zoom="6"
        :options="{
          minZoom: 4,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false,
        }"
        style="width: 100%; border-radius: 20px"
        class="rounded-md"
        :style="'height:100%'"
        ref="map"
        @mousemove="handleMouseMove"
        @mousedown.native="enableDragging"
        @mouseup.native="finalizePosition($event)"
      >
        <GmapRectangle
          v-if="rectangleBounds"
          :bounds="rectangle ? rectangle : rectangleBounds"
          :editable="true"
          :draggable="true"
          @bounds_changed="onBoundsChanged"
        />

        <GmapMarker :position="startPosition" @click="center = startPosition" />

        <GmapMarker
          v-for="(item, index) in bridgesList"
          :key="'bridge-' + index"
          :icon="require('@/assets/marker/bridge.png')"
          :position="{ lat: item.startLat, lng: item.startLng }"
          :label="{
            text: item.name,
            color: 'black',
            className:
              'absolute top-1 left-0  text-sm  bg-white rounded px-2 text-xs font-bold',
            fontSize: '10px',
          }"
        />

        <GmapMarker
        v-for="(item, index) in customPoints"
        :key="'bridge-' + index"
        :icon="require('@/assets/marker/customspoints.png')"
        :position="{ lat: Number(item.latitude), lng: Number(item.longitude) }"
        :label="{
          text: item.name + ' Sınır Kapısı',
          color: 'black',
          className:
            'absolute top-1 left-0  text-sm  bg-white rounded px-2 text-xs font-bold',
          fontSize: '10px',
        }"
      />



        <GmapPolyline
          v-bind:path.sync="polyLineList"
          v-bind:options="{ strokeWidth: 20, strokeColor: '#C43A3A' }"
        >
        </GmapPolyline>
        <GmapMarker
          v-for="(item, index) in filterWaypoints()"
          v-show="item.no"
          :key="index"
          :position="item"
          :label="{
            text: item.no + '. Teslim Noktası',
            color: 'white',
            className:
              'absolute top-0.5 left-0  text-sm  bg-black rounded px-2 text-xs font-bold text-white',
            fontSize: '10px',
          }"
          :icon="{
            url: locationPinImages[(item.no - 1) % 5],
            scaledSize: { width: 24, height: 24 },
          }"
        />

        <GmapCircle
          v-if="circleCenter"
          :center="circleCenter"
          :radius="50"
          :editable="false"
          :draggable="isDragging"
          ref="circleRef"
        />

        <div v-for="(item, index) in tollsList" :key="'toll-' + index">
          <GmapMarker
            v-if="item.typeId === 1"
            :position="{ lat: item.startLat, lng: item.startLng }"
            :icon="require('@/assets/marker/hgs.png')"
            @click="center = { lat: item.startLat, lng: item.startLng }"
            :label="{
              text: `Giriş : ${item.name}`,
              color: 'black',
              className:
                'absolute top-1 left-0  text-sm  bg-white rounded px-2 text-xs font-bold',
              fontSize: '10px',
            }"
          />

          <GmapMarker
            v-if="item.typeId === 1"
            :position="{ lat: item.endLat, lng: item.endLng }"
            :icon="require('@/assets/marker/hgs.png')"
            @click="center = { lat: item.endLat, lng: item.endLng }"
            :label="{
              text: `Çıkış : ${item.name}`,
              color: 'black',
              className:
                'absolute top-1 left-0 text-sm  bg-white  rounded px-2 text-xs font-bold',
              fontSize: '10px',
            }"
          />

          <GmapMarker
            v-if="item.typeName === 2"
            :position="{ lat: item.startLat, lng: item.startLng }"
            :icon="require('@/assets/marker/barrier.png')"
            @click="center = { lat: item.startLat, lng: item.startLng }"
            :label="{
              text: item.name,
              color: 'black',
              className:
                'absolute top-1 -right-1 text-sm   rounded bg-white px-2 text-xs font-bold',
              fontSize: '10px',
            }"
          />
        </div>

        <GmapMarker
          :position="endPosition"
          :icon="require('@/assets/marker/flag.png')"
          @click="center = endPosition"
        />
      </GmapMap>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { routeEngine } from "@/networking/routeEngine";

import { ToggleButton } from "vue-js-toggle-button";

import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import gmapInputVue from "@/components/general/gmapInput.vue";
import hgsDetail from "./detail/hgs-detail.vue";

import { decode } from "../../../utils/encoder";
import { managementCosting } from "../../../networking/urlmanager";

export default {
  name: "full-routes",
  props: [
    "waypoints",
    "startPosition",
    "endPosition",
    "polyLine",
    "forbidenBounds",
    "avoidPointsList",
    "tolls",
    "kilometer",
    "routes",
    "bridges",
    "isExplosiveDefault",
    "customerStateId",
    "outpointStateId",
    "outpointId",
    "customerId",
    "custompoints"
  ],
  components: {
    diffInput,
    ToggleButton,
    asyncBtn,
    hgsDetail,
    gmapInputVue,
  },

  data() {
    return {
      center: null,
      totalKilometer: 0,
      totalMinutes: 0,
      loadState: false,
      isToll: 1,
      isShip: 0,
      isAsphalt: 0,
      isExplosive: 0,
      vehicleTypeId: 5,
      routeType: 1,
      vehicleTypes: [],
      customPoints:[],
      fuelPrice: 1,
      locationPinImages: [
        require("@/assets/marker/location-pin.png"),
        require("@/assets/marker/location-pin-2.png"),
        require("@/assets/marker/location-pin-3.png"),
        require("@/assets/marker/location-pin-4.png"),
        require("@/assets/marker/location-pin-5.png"),
      ],

      waypointsList: [],
      polyLineList: [],
      tollsList: [],
      rectangle: null,
      pricingRoadList: [],
      avoidList: [],

      bridgesList: [],
      load: false,

      route: {},

      rectangleBounds: null,

      circleCenter: { lat: 41.015137, lng: 28.97953 },
      isDragging: false,
      isCircleVisible: false,
    };
  },
  methods: {
    filterWaypoints() {
      return this.waypoints.filter((r) => r.type != "route");
    },
    handleMouseMove(event) {
      const clickPoint = event.latLng;
      const polyline = new google.maps.Polyline({
        path: this.polyLineList,
      });
      if (
        this.isDragging ||
        google.maps.geometry.poly.isLocationOnEdge(clickPoint, polyline, 10e-4)
      ) {
        // Hassasiyeti ayarlayın
        this.circleCenter = {
          lat: clickPoint.lat(),
          lng: clickPoint.lng(),
        };
      }
    },
    enableDragging(event) {
      const bounds = this.$refs.map.$mapObject.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const scale = Math.pow(2, this.$refs.map.$mapObject.getZoom());
      const nw = new google.maps.LatLng(ne.lat(), sw.lng());
      const worldCoordinateNW = this.$refs.map.$mapObject
        .getProjection()
        .fromLatLngToPoint(nw);
      const worldCoordinate = new google.maps.Point(
        worldCoordinateNW.x + event.offsetX / scale,
        worldCoordinateNW.y + event.offsetY / scale
      );
      const latLng = this.$refs.map.$mapObject
        .getProjection()
        .fromPointToLatLng(worldCoordinate);

      const clickPoint = latLng;
      const polyline = new google.maps.Polyline({
        path: this.polyLineList,
      });

      if (
        google.maps.geometry.poly.isLocationOnEdge(clickPoint, polyline, 10e-3)
      ) {
        // Hassasiyeti ayarlayın
        const map = this.$refs.map.$mapObject;
        map.setOptions({ draggable: false });
        this.isDragging = true;
        this.isCircleVisible = true; // Circle'ı sadece sürüklenme sırasında göster
      }
    },
    finalizePosition(event) {
      if (this.isDragging) {
        this.waypointsList.push({
          type: "route",
          step: this.waypointsList.filter((r) => r.type == "route").length + 1,
          lat: this.circleCenter.lat,
          lng: this.circleCenter.lng,
        });

        const map = this.$refs.map.$mapObject;
        map.setOptions({ draggable: true });

        this.isDragging = false;
        this.getRoute(true);
        // Burada yeni pozisyonu kullanarak rota güncelleme işlemi yapın
      }
    },
    addAvoidPoint(item) {
      const params = {
        id: item.id,
        name: item.name,
        typeId: item.typeId,
        price: item.price,
      };
      this.avoidList.push(params);
      this.getRoute();
    },
    removeAvoidList(item) {
      this.avoidList = this.avoidList.filter((i) => i.id != item.id);
      this.getRoute();
    },
    undoWaypoint() {
      // isWaypoint olmayan waypointleri filtrele
      const nonWaypointList = this.waypointsList.filter(
        (item) => !item.isWaypoint
      );

      // Filtrelenmiş listeden en yüksek adımlı waypoint'i bul
      const result = nonWaypointList.reduce(
        (acc, item, index) => {
          if (
            item.type === "route" &&
            item.step !== undefined &&
            item.step > acc.maxStep
          ) {
            return { maxStep: item.step, maxStepIndex: index };
          }
          return acc;
        },
        { maxStep: -Infinity, maxStepIndex: -1 }
      );

      console.log(
        "Filtrelenmiş listeden en yüksek adımlı waypoint'i bul : ",
        result
      );

      // Geçerli bir waypoint bulunup bulunmadığını kontrol et
      if (result.maxStepIndex === -1) {
        console.warn("No valid waypoint found to undo.");
        return;
      }

      // Orijinal listedeki en yüksek adımlı waypoint'in indeksini bul
      const originalIndex = this.waypointsList.findIndex(
        (item) =>
          item &&
          nonWaypointList[result.maxStepIndex] &&
          item.id === nonWaypointList[result.maxStepIndex].id &&
          item.step === nonWaypointList[result.maxStepIndex].step
      );

      // Eğer geçerli bir indeks bulunmuşsa bu waypoint'i sil
      if (originalIndex > -1) {
        this.waypointsList.splice(originalIndex, 1);
        this.getRoute(true);
      } else {
        console.warn("Original index not found.");
      }
    },
    changeDrag(val) {
      this.waypointsList.push({
        type: "route",
        step: this.waypointsList.filter((r) => r.type == "route").length + 1,
        lat: val.latLng.lat(),
        lng: val.latLng.lng(),
      });
      this.getRoute(true);
    },
    getTimeDetail(mins) {
      let minutes = parseFloat(mins);
      const h = (minutes / 60) | 0,
        m = minutes % 60 | 0;

      return h + " Saat" + " " + m + " Dakika";
    },
    async getManagmentConst() {
      const res = await axios.get(managementCosting.getAll, {
        headers: {
          Authorization: "Bareer " + this.$store.state.userData.token,
        },
      });
      const kilometerForFuel = 0.34;
      const fuelPrice = Number(res.data.data[20].price.replace(",", "."));

      this.fuelPrice = this.route.kilometer * kilometerForFuel * fuelPrice;
    },
    async getVehicleTypes() {
      const result = await axios.get(routeEngine.getVehicleTypes, {
        headers: {
          Authorization: "Bareer " + this.$store.state.routeEngineToken,
        },
      });
      this.vehicleTypes = result.data.data;
    },
    toggleRectangle() {
      if (this.rectangleBounds) {
        this.rectangleBounds = null;
        this.removeAvoidList({ id: "custom" });
        return;
      }
      const map = this.$refs.map.$mapObject;
      const center = map.getCenter();
      const bounds = {
        north: center.lat() + 0.05,
        south: center.lat() - 0.05,
        east: center.lng() + 0.05,
        west: center.lng() - 0.05,
      };
      this.rectangleBounds = bounds;
    },
    onBoundsChanged(val) {
      this.rectangle = val;
      const findItem = this.avoidList.findIndex((r) => r.id == "custom");
      const firstValue = Object.values(val)[0];
      const secondValue = Object.values(val)[1];
      if (findItem === -1) {
        this.avoidList.push({
          id: "custom",
          name: "custom",
          typeId: 4,
          positions: [
            {
              lng: String(secondValue.hi),
              lat: String(firstValue.hi),
            },
            {
              lng: String(secondValue.lo),
              lat: String(firstValue.lo),
            },
          ],
        });
      } else {
        this.avoidList[findItem].positions = [
          {
            lng: String(secondValue.hi),
            lat: String(firstValue.hi),
          },
          {
            lng: String(secondValue.lo),
            lat: String(firstValue.lo),
          },
        ];
      }
    },
    getRoute(loadState = true) {
      this.load = loadState;

      const newWaypointsList = this.waypointsList.map((item) => ({
        lat: item.lat,
        lng: item.lng,
      }));

      const newAvoidpointsList = this.avoidList.map(
        ({ id, name, typeId, positions }) => {
          const avoidPoint = { id, name };

          if (typeId) {
            avoidPoint.typeId = typeId;
          }
          if (positions) {
            avoidPoint.positions = positions;
          }

          return avoidPoint;
        }
      );

      axios
        .post(
          routeEngine.generate,
          {
            from: this.startPosition,
            to: this.endPosition,
            waypoints: newWaypointsList,
            avoidPoints: newAvoidpointsList,
            isToll: this.isToll,
            vehicleTypeId: this.vehicleTypeId,
            routeType: this.routeType == 1 ? "fast" : "short",
            shippedHazardousGoods: this.isExplosive,
            isShip: this.isShip,
            isAsphalt: this.isAsphalt,
            fuelRatio: 35,
            isPolyline: 1,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.routeEngineToken,
            },
          }
        )
        .then((res) => {
          this.route = res.data.data;

          this.$emit("changeEndPosition", this.endPosition);
          this.$emit("changeCustomerState", this.customerStateId);
          this.$emit("changeCustomerId", this.customerId);

          this.$emit("changeOutpointStateId", this.outpointStateId);
          this.$emit("changeOutpointId", this.outpointId);
          this.$emit("changeStartPosition", this.startPosition);

          this.routeGenerate();
          this.getManagmentConst();
        })
        .catch((err) => {
          console.log(err.response);
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;

          const map = this.$refs.map.$mapObject;
          map.setOptions({ draggable: true });
          this.isDragging = false;
          this.circleCenter = null;
        });
    },
    routeGenerate() {
      const data = this.route;

      this.pricingRoadList = [];
      this.polyLineList = decode(data.routePolyline).polyline;

      const parsedCostDetail = data.costDetail.map((item) => {
        return {
          ...item,
          startLat: Number(item.startLat),
          startLng: Number(item.startLng),
          endLat: Number(item.endLat),
          endLng: Number(item.endLng),
        };
      });
      this.customPoints=Array.isArray(data?.customPoints) ? data?.customPoints : [];
      const tollsArr = parsedCostDetail.filter(
        (item) => item.typeName !== "Bridge"
      );
      const bridgeArr = parsedCostDetail.filter(
        (item) => item.typeName === "Bridge"
      );

      this.tollsList = tollsArr;
      this.bridgesList = bridgeArr;

      this.pricingRoadList.push(...tollsArr);
      this.pricingRoadList.push(...bridgeArr);

      this.totalKilometer = data.kilometer;
      this.totalMinutes = data.totalMinutes;

      this.$emit("change", {
        cost: {
          fuel: data.fuelAmount,
          cash: data.totalCost,
        },
        polyLine: JSON.stringify({
          startPosition: this.startPosition,
          waypoints: this.waypoints,
          endPosition: this.endPosition,
        }),
        totalMinutes: data.totalMinutes,
        // navigationLink: data.navigationLink,
        navigationLink: data.routeViewUrl,
        totalKilometer: data.kilometer,
        tolls: tollsArr,
        bridges: bridgeArr,
      });
    },
    removePlace(item, index) {
      if (item.type == "route") {
        this.waypointsList.splice(index, 1);
        this.getRoute(true);
      }
    },
    getPlace(val) {
      this.waypointsList.push({
        lat: val.latLng.lat(),
        lng: val.latLng.lng(),
      });

      this.getRoute(true);
    },
    addMarker(val) {
      if (this.waypointsList.length < 10) {
        this.waypointsList.push({
          type: "route",
          lat: val.latLng.lat(),
          lng: val.latLng.lng(),
        });
        this.getRoute(true);
      } else {
        this.swalBox(
          "warning",
          "UYARI",
          "10'dan fazla ara nokta ekleyemezsiniz!",
          false,
          "Tamam"
        );
      }
    },
    setPlace(val, index) {
      this.waypointsList[index] = {
        type: this.waypointsList[index].type,
        lat: val.latLng.lat(),
        lng: val.latLng.lng(),
      };
      this.getRoute(true);
    },
    closeFullPanel() {
      this.$parent.routes = this.route;
      this.$parent.waypoints = this.waypointsList;
      this.$parent.avoidPointsList = this.avoidList;
      this.$parent.bridges = this.bridgesList;
      this.$parent.tolls = this.tollsList;
      this.$parent.polyLine = this.polyLineList;
      this.$parent.forbidenBounds = this.rectangle;
      this.$emit("hidePanel", true);
    },
    changeEndLocation(val) {
      this.endPosition = val;
      // this.getRoute();
    },
    changeStartLocation(val) {
      this.startPosition = val;
      // this.getRoute();
    },
    handlePolylineClick(event) {
      this.circleCenter = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },
  },
  created() {
    if (this.forbidenBounds) {
      this.rectangle = this.forbidenBounds;

      setTimeout(() => {
        this.toggleRectangle();
      }, 1000);

      setTimeout(() => {
        this.onBoundsChanged(this.forbidenBounds);
      }, 1500);
    }

    this.getManagmentConst();
    this.getVehicleTypes();

    this.center = this.startPosition;
    this.totalKilometer = this.routes.kilometer;
    this.totalMinutes = this.routes.totalMinutes;
    this.waypointsList = this.waypoints;
    this.avoidList = this.avoidPointsList;
    this.tollsList = this.tolls;
    this.bridgesList = this.bridges;
    this.pricingRoadList.push(...this.tolls);
    this.pricingRoadList.push(...this.bridges);
    this.polyLineList = this.polyLine;
    this.isExplosive = this.isExplosiveDefault
      ? this.isExplosiveDefault == true
        ? 1
        : 0
      : 0;
    this.customPoints=this.custompoints;
    this.route = this.routes;

    this.getRoute();
  },
  mounted() {
    this.$refs.map.$mapPromise.then((map) => {
      const polyline = new google.maps.Polyline({
        path: this.polylinePath,
        map: map,
        strokeColor: "#FF0000",
      });

      // Polyline'a bir tıklama işleyicisi ekleyin
      polyline.addListener("click", (event) => {
        this.handlePolylineClick(event);
      });
    });
  },
};
</script>

<style></style>
