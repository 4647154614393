<template>
  <modal
    name="report-history-log"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    :scrollable="true"
    @opened="show()"
  >
    <div
      class="flex justify-between card-header bgcol w-full xl:rounded-t text-white !z-50"
    >
      <h1 class="w-6/12 flex items-center h-4">AKSİYON RAPORU GEÇMİŞİ</h1>
      <button
        class=" "
        type="button"
        @click="$modal.hide('report-history-log')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <!-- <div class="w-full">
      <div
        class="shadow table-height-value overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-2"
      >
        <table class="min-w-full divide-y divide-gray-200 relative">
          <thead
            class="bg-white dark:bg-gray-700 border-black border-dotted border-b-2 border-opacity-20"
          >
            <tr>
              <th scope="col" class="table-th text-left">#</th>
              <th scope="col" class="table-th text-left">Kullanıcı</th>
              <th scope="col" class="table-th text-left z-50">Aksiyon</th>
              <th scope="col" class="table-th text-left z-50">Tarih</th>
            </tr>
          </thead>
          <tbody
            class="divide-y-2 divide-gray-500"
            style="background-color: #3a3a3a"
          >
            <tr v-show="!load" v-for="(item, index) in List" :key="item.id">
              <td class="table-td text-left">
                {{ index + 1 + currentPage * 30 }}
              </td>
              <td class="table-td text-left">{{ item.username }}</td>
              <td class="table-td text-left">
                {{ item.message }}
              </td>
              <td class="table-td text-left">
                {{ item.addDate | getDate }}
              </td>
            </tr>

            <tableLoader :load="load" :length="List.length" colspan="5" />
          </tbody>
        </table>

        <div :class="isFullScreen && 'absolute bottom-4 w-full bg-white'">
          <pagination
            v-if="totalCount > 0"
            :totalRowCount="totalCount"
            :pageCount="pageCount"
            :currentPage="currentPage + 1"
            @changePage="(r) => (currentPage = r - 1)"
          />
        </div>
      </div>
    </div> -->
    <LyrausTable
      :data="List"
      :columns="columns"
      :enablePagination="true"
      :isLoading="load"
      :totalCount="totalCount"
      :pageCount="pageCount"
      @update-current-page="handleCurrentPage"
      :searchBar="true"
      :handleSearch="handleSearch"
      :indexColumn="true"
      :paginationButtonColor="'red-600'"
      :paginationHoverColor="'red-700'"
      :alternateRowBg="['white', 'gray-100']"
      containerClass="shadow sm:rounded-lg m-4"
    ></LyrausTable>
  </modal>
</template>

<script>
//npm
import "moment/locale/tr";
import moment from "moment";
import { LyrausTable } from "lyraus-ui";
//Global Components
import pagination from "@/components/general/pagination.vue";
import tableLoader from "@/components/general/table-loader.vue";
import searchInput from "@/components/general/search-input.vue";

//Networking
import { bulkPlanning } from "@/networking/urlmanager";
import axios from "axios";

export default {
  name: "report-history-log-component",
  props: ["moduleId", "rowId", "shipId"],
  components: {
    pagination,
    tableLoader,
    searchInput,
  },
  data() {
    return {
      search: "",
      currentPage: 0,
      totalCount: 0,
      pageCount: 1,
      List: [],
      load: false,
      columns: [
        { key: "username", label: "Kullanıcı", width: 80 },
        {
          key: "title",
          label: "Aksiyon",
          extraKeys: ["vehicleCount"],
          width: 80,
        },
        { key: "note", label: "Rapor", width: 80 },
        { key: "date", label: "Tarih", width: 80, isDate: true, maxLength: 5 },
      ],
    };
  },
  methods: {
    show() {
      setTimeout(() => {
        this.getAll();
      }, 50);
    },
    handleSearch() {
      this.getAll(true);
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    getAll(isSearch = false) {
      if (isSearch) this.currentPage = 0;
      this.load = true;
      this.List = [];
      axios
        .get(
          bulkPlanning.actionLogs +
            "?search=" +
            this.search +
            "&rowId=" +
            this.rowId +
            "&page=" +
            this.currentPage,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;
          this.List = detail;
          this.load = false;
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LLL");
      else return "Tarih Yok";
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
