<template>
  <modal
    name="orderDetail-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '90%'"
    @opened="getAll()"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <div class="md:flex items-center justify-center w-full mt-4">
        <div class="w-full text-left">
          <div class="w-full md:flex items-center justify-between">
            <div class="w-3/12">
              <button @click="() => addOrder()" class="btn w-36 text-center">
                <i class="fas fa-plus mr-2"></i>Yeni Sipariş Oluştur
              </button>
            </div>
            <h4 class="inline-block w-6/12">SİPARİŞLER</h4>
          </div>
        </div>
        <div class="flex gap-4">
          <!-- ALPASLAN KULLANACAK -->
          <logBtn />
          <logModule :moduleId="10" :shipId="rowId" />
          <button class="p-2 w-1/12" @click="$modal.hide('orderDetail-modal')">
            <i class="fas fa-times float-right"></i>
          </button>
        </div>
      </div>
    </div>
    <reportModal :rowId="selected" :active="2" :moduleId="10" :shipId="rowId" />
    <!--  -->
    <div
      class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead
          class="bg-white border-black border-dotted border-b-2 border-opacity-20"
        >
          <tr>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 100px !important"
            >
              Sipariş No
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 200px !important"
            >
              Sipariş İsmi
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 180px !important"
            >
              Dolum
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 180px !important"
            >
              Teslim
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 180px !important"
            >
              Bitiş T.
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 180px !important"
            >
              Ürün
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 130px !important"
            >
              Toplam / Kalan
            </th>
            <!-- <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 100px !important"
            >
              Başlat / Durdur
            </th> -->
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 120px !important"
            >
              #
            </th>
          </tr>
        </thead>
        <tbody
          class="divide-y-2 divide-gray-500"
          style="background-color: #3a3a3a"
        >
          <tr v-for="item in List" :key="item.id" v-show="!load">
            <td class="table-td border-r border-opacity-20">
              {{ item.orderNo }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.orderName }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.receivedName }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.deliveredName }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.estimatedComplateDate }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.productType }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{
                Math.max(0, item.tonnage) +
                " / " +
                (0, item.tonnage - item.receivedTonnage).toFixed(2)
              }}
            </td>
            <!-- <td class="table-td border-r border-opacity-20 text-center">
              <asyncBtn
                @click="orderStatus(item, item.isStart == 1 ? 2 : 1)"
                :loadState="load"
                :text="item.isStart == 1 ? 'Durdur' : 'Başlat'"
                icon=""
                size="py-1 "
                :containerClass="
                  item.isStart === 2 || item.isStart === 3
                    ? 'bg-green-700 hover:bg-green-700 w-40'
                    : 'w-40'
                "
              />
            </td> -->
            <td class="table-td border-r border-opacity-20 text-center">
              <button class="process-btn" @click="detailItem(item)">
                <i class="fas fa-search"></i>
              </button>
              <button
                v-if="item.isStart === 3"
                class="process-btn mx-2"
                @click="deleteItem(item)"
              >
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <tableLoader :load="load" :length="List.length" colspan="9" />
        </tbody>
      </table>
    </div>
    <div class="px-4">
      <PaginationComponents
        v-if="totalCount > 0"
        :totalRowCount="totalCount"
        :pageCount="pageCount"
        :currentPage="currentPage + 1"
        @changePage="(r) => (currentPage = r - 1)"
      />
    </div>
  </modal>
</template>

<script>
//Npm
import moment from "moment";

//Global Components
import asyncBtn from "@/components/general/asyncBtn.vue";
import tableLoader from "@/components/general/table-loader.vue";
import PaginationComponents from "@/components/general/pagination.vue";
import reportModal from "@/components/modals/report.modal/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";
import logModule from "@/components/modals/logModule/index.vue";

//Networking
import axios from "axios";
import { bulkShipPlanning } from "@/networking/urlmanager";

export default {
  props: ["rowId"],
  components: {
    tableLoader,
    PaginationComponents,
    asyncBtn,
    reportModal,
    logModule,
    logBtn,
  },
  data() {
    return {
      load: false,
      List: [],
      stateId: 0,
      selected: null,
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      logBtn,
      logModule,
    };
  },
  methods: {
    addOrder() {
      this.$emit("isNewOrder", this.rowId);
      this.$modal.show("add-plan-modal");
      this.$emit("isLaterAddOrder", true);
    },
    // showRefresh() {
    //   this.getAll();
    // },
    detailItem(item) {
      this.selected = item.id;
      this.$modal.show("report-modal");
    },
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(bulkShipPlanning.orderdersgetAll + "?shipId=" + this.rowId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
          this.$emit("orderList", this.List);
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Planı silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              bulkShipPlanning.orderdersDelete,
              {
                rowId: item.id.toString(),
                shipId: this.rowId,
                tonnage: item.tonnage,
                orderNo: item.orderNo,
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.$emit("refresh", true);
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );

              if (!this.filterState) this.getAll();
              else this.getFilter();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    orderStatus(item, stateId) {
      this.load = true;
      axios
        .post(
          bulkShipPlanning.orderStatus,
          {
            stateId: stateId,
            orderId: item.id.toString(),
            shipId: this.rowId,
            orderNo: item.orderNo,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );

          this.getAll();
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
  },
  filters: {
    dateFormat(val) {
      return moment(val).format("LLL");
    },
  },
};
</script>
