var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"name":"preview-map-modal","height":_vm.isMobile ? '100%' : 'auto',"width":_vm.isMobile ? '100%' : '80%',"scrollable":true}},[_c('div',{staticClass:"pb-2 pt-4 pr-4 pl-4 border-b mb-4 border-gray-200 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 2xl:h-24 flex items-center justify-between"},[_c('h4',{staticClass:"text-xl font-semibold"},[_vm._v("Gerçekleşen Rota")]),_c('button',{staticClass:"px-3 text-lg",attrs:{"type":"button"},on:{"click":function($event){return _vm.$modal.hide('preview-map-modal')}}},[_c('i',{staticClass:"fas fa-times"})])]),(_vm.detail)?_c('div',{staticClass:"pb-4 px-4 pt-2 items-start justify-between 2xl:mb-20 mb-8"},[_c('div',{staticClass:"w-full pb-4 space-y-2"},[_c('label',{staticClass:"block font-bold text-lg mb-2"},[_vm._v("SİPARİŞ DETAYLARI")]),_c('div',{staticClass:"flex flex-row justify-between items-start space-x-4"},[_c('div',{staticClass:"w-1/2"},[_c('previewDetail',{attrs:{"label":"Güzergah ID","value":_vm.planData.routeId || '-'}}),_c('previewDetail',{attrs:{"label":"Yapılması Gereken Kilometre","value":_vm.planData.expectedKm}}),_c('previewDetail',{attrs:{"label":"Yapılan Kilometre","value":_vm.planData.actualKm}})],1),_c('div',{staticClass:"w-1/2"},[_c('previewDetail',{attrs:{"label":"Araç Plakası","value":_vm.planData.vehicle}}),_c('previewDetail',{attrs:{"label":"Doluma Giriş Tarihi","value":_vm.formattedEndDate(_vm.planData.entryDeliveryPointDate)}}),_c('previewDetail',{attrs:{"label":"Teslime Giriş Tarihi","value":_vm.formattedEndDate(_vm.planData.entryFillingPointDate)}})],1)])]),_c('div',{staticClass:"w-full"},[(_vm.detail)?_c('previewMap',{attrs:{"polyline":_vm.planData.polyline,"item":{
          ..._vm.detail,
          outpointLat: Number(this.detail.outpointLat),
          outpointLng: Number(this.detail.outpointLng),
          targetPointLat: Number(this.detail.targetPointLat),
          targetPointLng: Number(this.detail.targetPointLng),
        }}}):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }