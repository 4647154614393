<template>
  <modal
    name="preview-map-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    :scrollable="true"
  >
    <!-- Modal Header -->
    <div
      class="pb-2 pt-4 pr-4 pl-4 border-b mb-4 border-gray-200 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 2xl:h-24 flex items-center justify-between"
    >
      <h4 class="text-xl font-semibold">Gerçekleşen Rota</h4>
      <button
        type="button"
        class="px-3 text-lg"
        @click="$modal.hide('preview-map-modal')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Modal Content -->
    <div
      v-if="detail"
      class="pb-4 px-4 pt-2 items-start justify-between 2xl:mb-20 mb-8"
    >
      <!-- Recommendation Details -->
      <div class="w-full pb-4 space-y-2">
        <label class="block font-bold text-lg mb-2">SİPARİŞ DETAYLARI</label>
        <div class="flex flex-row justify-between items-start space-x-4">
          <div class="w-1/2">
            <previewDetail label="Güzergah ID" :value="planData.routeId || '-'" />

            <previewDetail
              label="Yapılması Gereken Kilometre"
              :value="planData.expectedKm"
            />
            <previewDetail label="Yapılan Kilometre" :value="planData.actualKm" />
          </div>
          <div class="w-1/2">
            <previewDetail label="Araç Plakası" :value="planData.vehicle" />
            <previewDetail
              label="Doluma Giriş Tarihi"
              :value="formattedEndDate(planData.entryDeliveryPointDate)"
            />
            <previewDetail
              label="Teslime Giriş Tarihi"
              :value="formattedEndDate(planData.entryFillingPointDate)"
            />
          </div>
        </div>
      </div>
      <!-- Map Section -->
      <div class="w-full">
        <previewMap
          v-if="detail"
          :polyline="planData.polyline"
          :item="{
            ...detail,
            outpointLat: Number(this.detail.outpointLat),
            outpointLng: Number(this.detail.outpointLng),
            targetPointLat: Number(this.detail.targetPointLat),
            targetPointLng: Number(this.detail.targetPointLng),
          }"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import moment from "moment";

// Local Components
import previewMap from "./previewMap.vue";
import previewDetail from "./previewDetail.vue";
import axios from "axios";
import { planning } from "../../../../../networking/urlmanager";

export default {
  name: "preview-map-modal",
  props: ["detail"],
  components: {
    previewMap,
    previewDetail,
  },
  data() {
    return {
      customer: "",
      hakedisKilometer: "",
      planData: null,
      mileageTraveled: null,
      formatedDetails: {
        ...this.detail,
        outpointLat: Number(this.detail.outpointLat),
        outpointLng: Number(this.detail.outpointLng),
        targetPointLat: Number(this.detail.targetPointLat),
        targetPointLng: Number(this.detail.targetPointLng),
      },
    };
  },
  methods: {
    formattedEndDate(val) {
      return val ? moment(val).format("DD/MM/YYYY HH:MM") : "-";
    },
    getDetails() {
      axios
        .get(`${planning.getPlanningInfo}?orderNo=${this.detail.orderNo}`, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          console.log("res", result.data.data[0]);
          this.planData = result.data.data[0]
        })
        .catch((err) => {
          this.errorBox(err.response);
        });
    },
  },
  filters: {
    formatCapacity(capacity) {
      if (typeof capacity === "number" && capacity >= 1000) {
        return capacity / 1000 + " TON";
      } else {
        return capacity + " TON";
      }
    },
  },
  watch: {
    detail() {
      this.getDetails();
    },
  },
};
</script>
