<template>
  <div class="flex w-full bg-white">
    <div class="min-h-screen 2xl:w-3/6 xl:w-4/12 md:w-5/12 max-sm:hidden block">
      <div
        class="min-h-screen hidden sm:flex w-full bg-blue-900 bg-no-repeat bg-cover bg-center"
        id="auth-explanation-panel"
        :style="'background-image: url(' + require('@/assets/auth/authbg.png') + ')'"
      >
        <div class="h-4/12">
          <img class="m-6" src="@/assets/auth/siriusLogo.png" alt="" />
        </div>

        <div class="bg-opacity-0 bg-black min-h-screen w-full">
          <div class="w-3/12 fixed left-6 bottom-60">
            <h1 class="text-5xl font-bold text-white pb-4">
              Zamanı En İyi Şekilde Kullan
            </h1>
            <p class="text-white">
              Zamanınızı ve kaynaklarınızı etkin bir şekilde yönetin. Yolculuklarınızı
              tasarruflu bir şekilde planlayarak, hızlı ve zamanında teslimatlar ile iş
              süreçlerinizi optimize edin.
            </p>
          </div>
          <div class="flex w-full gap-4 fixed left-6 bottom-20">
            <a href="https://www.facebook.com/sirius.grup.sirketi ">
              <img class="w-6 h-6" src="@/assets/auth/footerFacebook.png" alt=""
            /></a>
            <a href="https://www.instagram.com/sirius.tedarik/">
              <img class="h-6 w-6" src="@/assets/auth/instagram.png" alt="" />
            </a>
            <a href="https://www.linkedin.com/in/sirius-yaz%C4%B1l%C4%B1m-5a927224b/ ">
              <img class="h-6 w-6" src="@/assets/auth/linkedin.png" alt="" />
              /></a
            >
          </div>
          <a
            href="https://siriusgrup.com"
            target="_blank"
            class="fixed left-6 bottom-5 w-full text-white"
          >
            Tüm hakları saklıdır. © siriusgrup.com
          </a>
        </div>
      </div>
    </div>
    <div class="md:w-8/12 xl:w-8/12 2xl:w-9/12 w-full">
      <authForm v-if="step == 1" @fargotPassword="step = 2" />
      <forgatForm v-if="step == 2" @signIn="step = 1" />
    </div>
    <img
      class="h-screen md:block hidden fixed right-0"
      src="@/assets/auth/line.png"
      alt=""
    />
  </div>
</template>
<script>
//local Components
import authForm from "./components/authForm.vue";
import forgatForm from "./components/forgatForm.vue";

export default {
  components: {
    authForm,
    forgatForm,
  },
  data() {
    return {
      step: 1,
    };
  },
  created() {
    this.$store.commit("activeLightMode");
    document.documentElement.classList.remove("dark");

    if (this.isMobile) {
      this.$store.commit("hideNavState");
    }
  },
};
</script>
