<template>
  <div class="flex items-center w-full">
    <navbarItem
      v-if="authController(1)"
      id="1"
      title="Araç Takip"
      routeName="/vehicle-tracking"
      icon="fas fa-map-signs"
      @changeOpened="(val) => (opened = val)"
    />

    <navbarItem
      v-if="authController(2)"
      id="2"
      title="Hesap Makinesi"
      routeName="/calculator"
      icon="fas  fa-fw fa-calculator "
      @changeOpened="(val) => (opened = val)"
    />

    <navbarItem
      v-if="operationList.length > 0"
      title="Operasyon"
      :isMenu="true"
      icon="fas  fa-fw fa-list"
      :list="operationList"
      :opened="opened"
      @changeOpened="(val) => (opened = val)"
      id="11"
    />

    <navbarItem
      v-if="reports.length > 0"
      title="Raporlar"
      :isMenu="true"
      icon="fas fa-exchange-alt"
      :list="reports"
      @changeOpened="(val) => (opened = val)"
      :opened="opened"
      id="16"
    /> 

    <navbarItem
      v-if="toolsList.length > 0"
      title="Yönetim"
      :isMenu="true"
      icon="fas  fa-fw fa-cogs"
      :list="toolsList"
      @changeOpened="(val) => (opened = val)"
      :opened="opened"
      id="12"
    />
  </div>
</template>

<script>
import { authorityModule } from "../networking/urlmanager";
import store from "../store";
import navbarItem from "./components/navbar.item.vue";
// import darkModelItem from "./components/darkmode.item.vue";
export default {
  components: {
    navbarItem,
    // darkModelItem,
  },
  name: "sidebar",
  data() {
    return {
      authority: 1,
      opened: 0,
      operationList: [
        {
          id: 11,
          title: "Siparişler",
          url: "/orders",
          icon: "fa-chevron-right",
        },
        {
          id: 3,
          title: "Planlama",
          url: "/planning",
          icon: "fa-chevron-right",
        },
        {
          id: 12,
          title: "Toplu Planlama",
          url: "/collectivePlanning",
          icon: "fa-chevron-right",
        },

        {
          id: 10,
          title: "Gemi Tahliyesi",
          url: "/shipEvacuation",
          icon: "fa-chevron-right",
        },
        {
          id: 13,
          title: "Depo Tahliyesi",
          url: "/storageEvacuation",
          icon: "fa-chevron-right",
        },
        {
          id: 3,
          title: "Planlanmış Siparişler",
          url: "/planning/orders",
          icon: "fa-chevron-right",
        },
      ],
      reports: [
        {
          id: 16,
          title: "Araç Kilometreleri",
          url: "/vehicleKilometers",
          icon: "fa-chevron-right",
        },
        {
          id: 16,
          title: "Bekleme Raporu",
          url: "/customerWaitingTime",
          icon: "fa-chevron-right",
        },
        {
          id: 16,
          title: "Ziyaret Raporu",
          url: "/driverHomeVisitReport",
          icon: "fa-chevron-right",
        },
        {
          id: 16,
          title: "Yıama Raporu",
          url: "/washPointReport",
          icon: "fa-chevron-right",
        },

        
      ],
      toolsList: [
        {
          id: 5,
          title: "Ürünler",
          url: "/products",
          icon: "fa-chevron-right",
        },
        {
          id: 4,
          title: "Müşteriler",
          url: "/customers",
          icon: "fa-chevron-right",
        },
        {
          id: 6,
          title: "Sürücüler",
          url: "/staff",
          icon: "fa-chevron-right",
        },
        {
          id: 9,
          title: "Yıkama Noktaları",
          url: "/washPoint",
          icon: "fa-chevron-right",
        },
        {
          id: 14,
          title: "Şubeler",
          url: "/branches",
          icon: "fa-chevron-right",
        },
        {
          id: 15,
          title: "Dinlenme Noktaları",
          url: "/restStops",
          icon: "fa-chevron-right",
        },
        {
          id: 16,
          title: "Filo Yönetimi",
          url: "/fleetManagement",
          icon: "fa-chevron-right",
        },
        {
          id: 8,
          title: "Maliyet Kalemleri",
          url: "/management/costing",
          icon: "fa-chevron-right",
        },
        {
          id: 7,
          title: "Kullanıcılar",
          url: "/users",
          icon: "fa-chevron-right",
        },
      ],
    };
  },
  methods: {
    authController(moduleId) {
      return this.$store.state.userData.perms.some(
        (r) =>
          r.permtypeId === moduleId && (r.editState === 2 || r.editState === 1)
      );
    },
    getPermState(id) {
      const findItem = this.$store.state.userData.perms.find(
        (r) => r.permtypeId == id
      );
      return findItem ? findItem.editState > 0 : false;
    },
    shouldShowLink(pageId) {
      const pagePermission = this.$store.state.userData.perms.find(
        (permission) => permission.permtypeId === pageId
      );
      if (!pagePermission) {
        return false;
      }
      return pagePermission?.editState !== 0;
    },
  },
  created() {
    this.toolsList = this.toolsList.filter((r) => this.authController(r.id));
    this.operationList = this.operationList.filter((r) =>
      this.authController(r.id)
    );
  },
};
</script>
<style>
.active {
  border-left: 5px solid #fc0101 !important;
  border-radius: 0px !important;
  color: rgb(255, 255, 255) !important;
}

.custom-width:hover {
  width: 20%;
}
</style>
